import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedLayout = () => {
	const { loggedIn } = useSelector((state) => state.auth);

	if (!loggedIn) {
		return <Navigate to="/Account/Login" replace />;
	}

	return <Outlet />;
};

export default ProtectedLayout;
