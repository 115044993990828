import Estados from "../../config/estados";

function formatDate(date) {
	if (date && date !== '') {
		return new Date(date).toLocaleDateString('es-AR', {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
		});
	}

	return '';
}

const permitirSoloNumeros = (e) => {
	const invalidChars = ['-', '+', 'e', '.', 'E',',']
	if(invalidChars.indexOf(e.key) !== -1){
		e.preventDefault()
	}
}

function validarEmail(email) {
	const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return mailRegex.test(email);
}

function validarCuit(cuit) {
	//\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]
	const cuitRegex = /\d{11}/;
	return cuitRegex.test(cuit);
}

function generarId(){
	return (Math.random() + 1).toString(36).substring(2);
}

function obtenerNumeroFormateado(numero){
	if(numero === undefined || numero === null) 
		return "";
	
	return numero.toFixed(2).replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function descargar(archivo, nombre = "fileName"){
	if(!archivo) return

	var a = document.createElement("a");
	document.body.appendChild(a);
	a.style = "display: none";
	var blob = new Blob([archivo], {type: "application/octet-stream"});
	var url = window.URL.createObjectURL(blob);
	a.download = nombre
	a.href = url;
	a.click();
	window.URL.revokeObjectURL(url);
}

function visualizar(archivo){
	if(!archivo) return

	var a = document.createElement("a");
	document.body.appendChild(a);
	a.style = "display: none";
	var blob = new Blob([archivo], {type: "application/pdf"});
	var url = window.URL.createObjectURL(blob);
	a.target = "_blank"
	a.ref = "noreferred"
	a.href = url;
	a.click();
	window.URL.revokeObjectURL(url);
}

function bytesToSize(bytes) {
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];

	if (bytes === 0){
		return '0B'
	}

	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

	if (i === 0){
		return `${bytes} ${sizes[i]})`
	}

	return `${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`
}

function trimSpacesJson(json){
	try{
		return JSON.parse(JSON.stringify(json).replace(/"\s+|\s+"/g,'"'))
	}catch{}
	
	return json;
}

function obtenerColorEstado(idEstado){
	switch(idEstado){
		case Estados.Subido:
			return "success";
		case Estados.ListoParaFirmar:
			return "warning";
		case Estados.Firmado:
			return "primary";
		case Estados.Descargado:
			return "info"
		default:
			return "dark"
	}
}

export const Util = {
	formatDate,
	validarEmail,
	validarCuit,
	generarId,
	obtenerNumeroFormateado,
	descargar,
	permitirSoloNumeros,
	bytesToSize,
	trimSpacesJson,
	visualizar,
	obtenerColorEstado
}
