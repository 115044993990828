import axios from 'axios';
import TokenService from './tokenService';
import AuthService from './authService';
import { history, Util } from '../utils';
import { store, actions } from '../state';

export const urlApi = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/api/v1";
//export const urlApi = window.location.protocol + "//" + window.location.hostname + ":9562/api/v1";

const defaults = {
	baseURL: urlApi,
	headers: () => ({
		'Content-Type': 'application/json',
		Authorization: TokenService.getAccessToken() ? `Bearer ${TokenService.getAccessToken()}` : undefined,
	}),
};

const api = (method, url, variables, headers, options) => {
	store.dispatch(actions.loadingStart());

	return new Promise((resolve, reject) => {
		let apiHeaders = defaults.headers();

		if(headers){
			apiHeaders = {...apiHeaders, ...headers}
		}

		let data = undefined;
		
		if(method === "upload"){
			data = variables["formData"];
			apiHeaders["Content-Type"] = "multipart/form-data"
		}else if(method !== "get"){
			data = Util.trimSpacesJson(variables);
		}

		axios({
			url: `${defaults.baseURL}${url}`,
			method: method === "download" || method === "upload" ? "post" : method,
			headers: apiHeaders,
			params: method === 'get' ? variables : undefined,
			data: data,
			responseType: method === "download" ? "blob" : "json",
			...options
		}).then(
			(response) => {
				store.dispatch(actions.loadingEnd());
				resolve(response);
			},
			async (err) => {
				const originalConfig = err.config;

				if (originalConfig.url.endsWith('/actualizarToken') && err.response) {
					originalConfig._retry = false;
					AuthService.logout();
					history.push('/Account/Login');
					store.dispatch(actions.loadingEnd());
					return reject(err);
				}

				if (!originalConfig.url.endsWith('/login') && err.response) {
					// Access Token was expired
					if (err.response.status === 401 && err.response.data.error && err.response.data.error.toLowerCase().indexOf('token') > -1 && !originalConfig._retry) {
						originalConfig._retry = true;

						try {
							const rs = await api('post', '/actualizarToken', { refreshToken: TokenService.getRefreshToken() })
								.then((response) => response.data);

							const { accessToken, refreshToken } = rs.data;
							TokenService.updateAccessToken(accessToken);
							TokenService.updateRefreshToken(refreshToken);
							store.dispatch(actions.loadingEnd());

							const res = api(
								originalConfig.method,
								originalConfig.url.replace(defaults.baseURL, ''),
								originalConfig.method === 'get' ? originalConfig.params : originalConfig.data
							).then((response) => response.data);
							return resolve(res);
						} catch (_error) {
							store.dispatch(actions.loadingEnd());
							return reject(_error);
						}
					}
				}
				store.dispatch(actions.loadingEnd());
				return reject(err);
			}
		);
	});
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	get: (...args) => api('get', ...args),
	post: (...args) => api('post', ...args),
	put: (...args) => api('put', ...args),
	patch: (...args) => api('patch', ...args),
	delete: (...args) => api('delete', ...args),
	download: (...args) => api('download', ...args),
	upload: (...args) => api('upload', ...args),
};
