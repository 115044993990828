import { Fragment, Suspense, lazy, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedLayout from './ProtectedLayout';
import { CustomRouter } from './CustomRouter';
import { history } from '../shared/utils';
import UserService from '../shared/services/userService';
import Role from '../config/roles';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../shared/state';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse"></div>
	</div>
);

const NotFound = lazy(() => import('../pages/NotFound'));
const Home = lazy(() => import('../pages/Home'));
const Login = lazy(() => import('../pages/Account/Login/Login'));

const RecursosHumanos = {
	Usuarios: lazy(() => import('../pages/RecursosHumanos/Usuarios/Usuarios')),
	AdministrarAutorizaciones: lazy(() => import('../pages/RecursosHumanos/AdministrarAutorizaciones/AdministrarAutorizaciones')),
	CargaRecibos: lazy(() => import('../pages/RecursosHumanos/CargaRecibos/CargaRecibos')),
	Recibos: lazy(() => import('../pages/RecursosHumanos/Recibos/RecibosRecursosHumanos')),
	RegenerarFraseRRHH: lazy(() => import('../pages/RecursosHumanos/RegenerarFrase/RegenerarFraseRRHH')),
}

const Apoderado = {
	Recibos: lazy(() => import('../pages/Apoderado/Recibos/RecibosApoderado')),
}

const Personal = {
	Recibos: lazy(() => import('../pages/Personal/Recibos/RecibosPersonal')),
	CambiarFraseFirma: lazy(() => import('../pages/Personal/CambiarFraseFirma/CambiarFraseFirma')),
}

const AppRouter = () => {
	const dispatch = useDispatch();
	const { loggedIn } = useSelector((state) => state.auth);
	const { user } = useSelector((state) => state.user);
	const { obtenerUsuario } = bindActionCreators(actions, dispatch);

	useEffect(() => {
		if(loggedIn && !user){
			obtenerUsuario();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loggedIn]);

	const regexApi = /^\/api\/.*/;
	
	if (regexApi.test(window.location.pathname)) {
		return <div />;
	}

	return (
		<Fragment>
			<CustomRouter history={history}>
				<Suspense fallback={loading}>
					<Routes>
						{/* Unauthenticated Routes */}
						<Route path="/Account/Login" element={<Login />} />

						{/* Authenticated Routes */}
						<Route path="/" element={<ProtectedLayout />}>
							<Route path="/" exact element={<Home />} />

							{/* Admin */}
			

							{/* Recursos Humanos */}
							{UserService.tieneRol(Role.RRHH) && UserService.tieneRol(Role.VisualizarDatosUsuario) && <Route path="/RecursosHumanos/Usuarios" exact element={<RecursosHumanos.Usuarios />} />}
							{UserService.tieneRol(Role.RRHH) && <Route path="/RecursosHumanos/Recibos" exact element={<RecursosHumanos.Recibos />} />}
							{UserService.tieneRol(Role.RRHH) && <Route path="/RecursosHumanos/CargaRecibos" exact element={<RecursosHumanos.CargaRecibos />} />}
							{UserService.tieneRol(Role.RRHH) && <Route path="/RecursosHumanos/AdministrarAutorizaciones" exact element={<RecursosHumanos.AdministrarAutorizaciones />} />}
							{UserService.tieneRol(Role.RegenerarFraseRRHH) && <Route path="/RecursosHumanos/RegenerarFrase" exact element={<RecursosHumanos.RegenerarFraseRRHH />} />}
							
							{/* Apoderado */}
							{UserService.tieneRol(Role.Apoderado) && <Route path="/Apoderado/Recibos" exact element={<Apoderado.Recibos />} />}

							{/* Personal */}
							<Route path="/Personal/Recibos" exact element={<Personal.Recibos />} />
							<Route path="/Personal/CambiarFraseFirma" exact element={<Personal.CambiarFraseFirma />} />

							<Route path="*" element={<NotFound />} />
						</Route>
					</Routes>
				</Suspense>
			</CustomRouter>
		</Fragment>
	);
};

export default AppRouter;
