import api from './api';
import { store } from '../state';

const obtenerUsuario = () => {
	return api.post('/obtenerUsuario').then((response) => response.data);
};

const tieneRol = (rolName) => {
	var state = store.getState();
	var { user } = state.user;

	if (!user || !user.rol || user.rol.length === 0) {
		return false;
	}

	return user.rol.some((e) => e.idRol === rolName);
};

const UserService = {
	obtenerUsuario,
	tieneRol,
};

export default UserService;
