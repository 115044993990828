import api from './api';
import TokenService from './tokenService';

const login = (credenciales) => {
	return api.post('/login', credenciales).then((response) => {
		return response.data.data;
	});
};

const logout = () => {
	TokenService.removeUser();
};

const AuthService = {
	login,
	logout,
};

export default AuthService;
