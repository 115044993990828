import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { userReducer } from './userReducer';
import { loaderReducer } from './loaderReducer';
import { progressReducer } from './progressReducer';
import { menuReducer } from './menuReducer';
import { reducer as reduxModalReducer } from 'redux-modal';

const reducers = combineReducers({
	auth: authReducer,
	user: userReducer,
	loader: loaderReducer,
	progress: progressReducer,
	menu: menuReducer,
	modal: reduxModalReducer,
});

export default reducers;
