import { authTypes } from '../types';
import AuthService from '../../services/authService';
import { history } from '../../utils';
import TokenService from '../../services/tokenService';
import { mostrarModal } from '../../hooks/modal';

export const login = (credenciales) => {
	return (dispatch) => {
		dispatch({ type: authTypes.loginRequest, email: credenciales.email });
		AuthService.login(credenciales).then(
			(user) => {
				TokenService.setUser(user);
				dispatch({ type: authTypes.loginSuccess, user });
				history.push('/');
			},
			(error) => {
				dispatch({ type: authTypes.loginFailure, error });
				mostrarModal('LoginErroneo');
			}
		);
	};
};

export const logout = () => {
	return (dispatch) => {
		AuthService.logout();
		dispatch({ type: authTypes.logout });
		history.push('/');
	};
};
