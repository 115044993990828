import Cookies from 'js-cookie';

const getRefreshToken = () => {
	const refreshToken = Cookies.get('Recibos__refreshToken');
	return refreshToken;
};

const getAccessToken = () => {
	const accessToken = Cookies.get('Recibos__sessionId');
	return accessToken;
};

const updateAccessToken = (token) => {
	let expirationTime = new Date();
	expirationTime.setTime(expirationTime.getTime() + 8766 * 3600 * 1000);
	Cookies.set('Recibos__sessionId', token, { path: '/', expires: expirationTime });
};

const updateRefreshToken = (token) => {
	let expirationTime = new Date();
	expirationTime.setTime(expirationTime.getTime() + 8766 * 3600 * 1000);
	Cookies.set('Recibos__refreshToken', token, { path: '/', expires: expirationTime });
};

const removeUser = () => {
	Cookies.remove('Recibos__refreshToken', { path: '/' });
	Cookies.remove('Recibos__sessionId', { path: '/' });
	window.location.reload();
};

const setUser = (res) => {
	updateAccessToken(res.tokenAcceso);
	updateRefreshToken(res.tokenRefresco);
};

const TokenService = {
	getRefreshToken,
	getAccessToken,
	updateAccessToken,
	updateRefreshToken,
	removeUser,
	setUser,
};

export default TokenService;
