const Role = {
	Empleado: 'Empleado',				//Rol para el empleado
	Apoderado: 'Apoderado', 			//Rol para el apoderado
	RRHH: 'RRHH', 						//Rol para recursos humanos
	VisualizarDatosUsuario: 'VisualizarDatosUsuario', //Rol para visualizar los datos de los usuarios
	ModificarDatosUsuario: 'ModificarDatosUsuario', //Rol para modificar los datos del usuario
	CambiarClaveUsuario: 'CambiarClaveUsuario', //Rol para cambiar la clave del usuario
	RegenerarFraseUsuario: 'RegenerarFraseUsuario', //Rol para regenerar la frase del usuario
	RegenerarFraseRRHH: 'RegenerarFraseRRHH', //Rol para regenerar la frase del usuario
}

export default Role;